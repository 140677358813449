import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./CarouselStrategy.css";
import Icon1 from "../../img/icons-SVG/icon-009.svg";
import Icon2 from "../../img/icons-SVG/icon-010.svg";
import Icon3 from "../../img/icons-SVG/icon-011.svg";
import Icon4 from "../../img/icons-SVG/icon-012.svg";
import Icon5 from "../../img/icons-SVG/icon-013.svg";
import Icon6 from "../../img/icons-SVG/icon-014.svg";

// const handleDragStart = (e) => e.preventDefault();

const items = [
  {
    img: Icon1,
    title: "Saiba quem Acessa",
    description:
      "Transforme seus visitantes em clientes com as melhores estratégias de otimização para o seu e-commerce.",
  },
  {
    img: Icon2,
    title: "Personalize com IA",
    description:
      "Unifique dados para aumentar interaçoes de forma inteligente, impulsionando seus resultados.",
  },
  {
    img: Icon3,
    title: "SKU Dinâmico",
    description:
      "Queime todo seu estoque por meio de ofertas exclusivas e campanhas mais eficazes.",
  },
  {
    img: Icon4,
    title: "Ativação",
    description:
      "Nossos canais digitais permitem que você alcance seus clientes no momento e hora certa.",
  },
  {
    img: Icon5,
    title: "Aumento de Conversão",
    description:
      "Impulsione o ROI e otimizo investimentos em marketing com uma análise de dados e baseada em IA.",
  },
  {
    img: Icon6,
    title: "Business Intelingence",
    description:
      "crie relatórios gerenciais de alta performance maximizando o potencial de cada público.",
  },
];

const Card = ({ img, title, description }) => (
  <div className="cardClientBox">
    <div className="clientDiv">
      <img src={img} alt="icon" className="clientIcons" />
      <h2 className="clientTitle">{title}</h2>
      <p className="clientParagraph">{description}</p>
    </div>
  </div>
);

const Gallery = () => {
  const carouselRef = useRef(null);

  const responsive = {
    0: { items: 1 },
    679: { items: 2 },
  };

  const renderedItems = items.map((item, index) => (
    <Card
      key={index}
      img={item.img}
      title={item.title}
      description={item.description}
      index={item.index}
    />
  ));

  // console.log(Card.index);
  return (
      <AliceCarousel
        mouseTracking
        items={renderedItems}
        ref={carouselRef}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlayInterval={3000}
      />
  );
};

export default Gallery;
