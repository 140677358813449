import styles from "./Card.module.css";
import Icon001 from "../../img/Icons-PNG/icon-001.png";
import Line from "../../img/icons-SVG/Line-01.svg"

const Card = () => {
  return (
    <>
    <div className={styles.globalCard}>
    <div className={styles.mainCard}>
      <div className={styles.containerCard}>
        <div className={styles.textCard}>
          <h1>
            Aumente seu <span>ROI</span>
          </h1>

          <p>
            Faça seu e-commerce decolar e alavancar, enquanto aproveita a
            eficácia e facilidade da nossa plataforma. Trazemos produtividade e
            automação transformando dados em conversão.
          </p>

          <p>
            Com os Reports personalizados você pode realizar uma análise precisa
            de suas campanhas e identificar dentro do seu SKU, os produtos que
            mais vendem para um aumento de ROI.
          </p>

          <p>A <span>solução</span> ideal para atrair mais clientes para seu <span>e-commerce!</span></p>
        </div>
        <img src={Icon001} alt="icone" />
      </div>
    </div>
    <img src={Line} alt="icone" className={styles.mainLine}/>
    </div>
    </>
  );
};

export default Card;
