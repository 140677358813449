import styles from "./Forms.module.css";
import { FaRegUser, FaRegBuilding } from "react-icons/fa";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { FiMail } from "react-icons/fi";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Forms = () => {
  const [nome, setNome] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const origin = "ecomtraffega.com.br";

  const submitForm = (formEvent) => {
    // console.log("formEvent", formEvent);
    setLoader(true);

    formEvent.preventDefault();

    const submitParams = {
      nome,
      empresa,
      telefone,
      email,
      origin,
    };
    const submitUrl = "https://form.ecomtraffega.com.br/op/forms/send";

    //const qs = new URLSearchParams(submitParams).toString();

    axios
      .post(submitUrl, submitParams)
      .then(function (response) {
        // alert("sucesso");
        setLoader(false);
        toast.success("Formulário enviado com sucesso!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setEmail("");
        setEmpresa("");
        setNome("");
        setTelefone("");
      })
      .catch(function (error) {
        setLoader(false);
        toast.error("Tente novamente mais tarde.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    return false;
  };

  return (
    <div className={styles.containerForms}>
      <div className={styles.backgroundForms}>
        <h1 className={styles.titleForm}>
          Nunca foi tão <span>fácil</span> começar
        </h1>
        <div className={styles.formsAndTitle}>
          <h3 className={styles.subtitleForms}>
            Agende sua Demo
          </h3>
          <form
            className={styles.form}
            onSubmit={submitForm}
            method="post"
            id="forms"
          >
            <div className={styles.globalInput}>
              <div className={styles.containerInput}>
                <input
                  type="text"
                  placeholder="Nome"
                  name="nome"
                  id="nome"
                  className={styles.input}
                  required
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
                <FaRegUser className={styles.icon} />
              </div>
              {!loader ? (
                ""
              ) : (
                <div className={styles.containerLoader}>
                  <div className={styles.customLoader}></div>
                </div>
              )}
              <div className={styles.containerInput}>
                <input
                  type="text"
                  placeholder="Empresa"
                  name="empresa"
                  id="empresa"
                  className={styles.input}
                  required
                  value={empresa}
                  onChange={(e) => setEmpresa(e.target.value)}
                />
                <FaRegBuilding className={styles.icon} />
              </div>
              <div className={styles.containerInput}>
                <input
                  type="text"
                  placeholder="Telefone"
                  name="telefone"
                  id="phone"
                  className={styles.input}
                  required
                  value={telefone}
                  onChange={(e) => setTelefone(e.target.value)}
                />
                <HiOutlineDevicePhoneMobile className={styles.icon} />
              </div>
              <div className={styles.containerInput}>
                <input
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  id="email"
                  className={styles.input}
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <FiMail className={styles.icon} />
              </div>
            </div>
            <input type="hidden" name="msgemailid" value="15" />
            <input type="hidden" name="clientId" value="16_504c546092" />
            <input
              type="hidden"
              name="redirect"
              value="https://ecomtraffega.com.br"
            />
            <p className={styles.policies}>
              • Ao preencher o formulário, concordo em receber comunicações.{" "}
              <br />• Informando meus dados, concordo com a Politica de
              privacidade.
            </p>
            <p className={styles.sucess}></p>
            <button type="submit" className={styles.buttonForm}>
              Enviar
            </button>
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Forms;
