import BlueLogo from "../../img/Icons-PNG/logo-color.png";
import { BiLogoWhatsapp } from "react-icons/bi";
import "./NavPolicy.css";
import {Link} from 'react-router-dom'

function NavbarComponent() {
  return (
    <div className="navbarStructurePolicies">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src={BlueLogo} alt="icon" className="logoNav"/>
          </a>
          <button
            className="navbar-toggler policy"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <p className="verticalLinePolicies"></p>
            <ul className="navbar-nav policy">
              <li className="nav-itemPolicies">
                <Link to="/" className="nav-link" aria-current="page" href="#">
                  Home
                </Link>
              </li>
              <li className="nav-itemPolicies">
                <a
                  className="nav-link"
                  href="https://amwgroup.com.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Group
                </a>
              </li>
              <li className="nav-itemPolicies">
                <a
                  className="nav-link"
                  href="https://traffega.com.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Traffega
                </a>
              </li>
              <li className="nav-itemPolicies">
                <a
                  className="nav-link"
                  href="https://amwgroup.com.br/about"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sobre
                </a>
              </li>
              <li className="nav-itemPolicies lastCont">
                <a
                  className="nav-link"
                  aria-disabled="true"
                  id="contate"
                  href="https://api.whatsapp.com/send?phone=5511992089747&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20E-COM%20Traffega."
                  target="_blank"
                  rel="noreferrer"
                >
                  Contate-nos
                  <BiLogoWhatsapp className="logoWhatsappPolicies" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavbarComponent;
