import styles from "./Strategy.module.css";
import Line from "../../img/icons-SVG/Line-01.svg";
import Icon1 from "../../img/icons-SVG/icon-009.svg";
import Icon2 from "../../img/icons-SVG/icon-010.svg";
import Icon3 from "../../img/icons-SVG/icon-011.svg";
import Icon4 from "../../img/icons-SVG/icon-012.svg";
import Icon5 from "../../img/icons-SVG/icon-013.svg";
import Icon6 from "../../img/icons-SVG/icon-014.svg";
import CarouselStrategy from "../CarouselStrategy/CarouselStrategy";

const Strategy = () => {
  return (
    <div className={styles.mainStrategy}>
      <div className={styles.initialText}>
        <h1>Estratégia de negócio</h1>
        <p>
          Crie uma experiência de compra única e personalizada para seu
          consumidor.
        </p>
      </div>
      <img src={Line} alt="Line" className={styles.lineDotted} />

      <div className={styles.carouseMobile}>
        <CarouselStrategy />
      </div>
      <div className={styles.cardsStrategy}>
        <div className={styles.cardIndividual}>
          <img src={Icon1} alt="icon" />
          <h3>Saiba quem Acessa</h3>
          <p>
            Transforme seus visitantes em clientes com as melhores estratégias
            de otimização para o seu e-commerce.
          </p>
        </div>
        <span className={styles.lineVertical}></span>

        <div className={styles.cardIndividual}>
          <img src={Icon2} alt="icon" />
          <h3>Personalize com IA</h3>
          <p>
            Unifique dados para aumentar interações de forma inteligente,
            impulsionando seus resultados.
          </p>
        </div>
        <span className={styles.lineVertical}></span>

        <div className={styles.cardIndividual}>
          <img src={Icon3} alt="icon" />
          <h3>SKU Dinâmico</h3>
          <p>
            Queime todo seu estoque por meio de ofertas exclusivas e Companhas
            mois eficazes.
          </p>
        </div>

        <div className={styles.cardIndividual}>
          <img src={Icon4} alt="icon" />
          <h3>Ativação</h3>
          <p>
            Nossos canais digitais permitem que você alcance seus clientes no
            momento e hora Certa.
          </p>
        </div>
        <span className={styles.lineVertical}></span>

        <div className={styles.cardIndividual}>
          <img src={Icon5} alt="icon" />
          <h3>Aumento de Conversão</h3>
          <p>
            Impulsione o ROI e otimizo investimentos em marketing com uma
            análise de dados e baseada em IA.
          </p>
        </div>
        <span className={styles.lineVertical}></span>

        <div className={styles.cardIndividual}>
          <img src={Icon6} alt="icon" />
          <h3>Business Intelingence</h3>
          <p>
            Crie relatórios gerenciais de alta performance maximizando o
            potencial de cada público.
          </p>
        </div>
      </div>
      <img src={Line} alt="Line" className={styles.lineDotted} />
    </div>
  );
};

export default Strategy;
