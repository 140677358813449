import React from "react";
import styles from "./Persona.module.css";
import artBoardLeft from "../../img/icons-SVG/artboardleft.svg";
import artBoardRight from "../../img/icons-SVG/artboardright.svg";
import Line from "../../img/icons-SVG/Line-02.svg";

const Persona = () => {
  function toBottom() {
    window.scrollTo(0,  document.body.scrollHeight);
  }
  return (
    <div className={styles.mainPersona}>
      <img src={artBoardLeft} alt="artboard" className={styles.artBoardLeft} />

      <div className={styles.mainCard}>
        <div className={styles.alignCard}>
          <h2>Plataforma 360°</h2>
          <p>para você vender todos os dias!</p>
          <img src={Line} alt="line" />
          <p>
            Descubra as necessidades de cada persona em tempo real ao coletar
            dados da experiência de navegação do seu site. Ofereça interações
            proativas, converta mais e reduza custos ao automatizar processos
            com base no comportamento do cliente. Crie gatilhos inteligentes
            para engajamento ao analisar métricas segmentadas de campanhas.
          </p>

          <div className={styles.containerBtnCard}>
            <button className={styles.btnCard} onClick={toBottom}>Saiba mais</button>
          </div>
        </div>
      </div>

      <img
        src={artBoardRight}
        alt="artboard"
        className={styles.artBoardRight}
      />
    </div>
  );
};

export default Persona;
