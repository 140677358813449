import React, { useState, useEffect } from "react";
import NavbarComponent from "../../components/Navbar/NavbarComponent";
import styles from "./Intro.module.css";
import iconLeft from "../../img/Icons-PNG/4-linhas-esquerda copy.png"
import iconRight from "../../img/Icons-PNG/4-linhas-direita copy.png"

const Intro = () => {
  function toBottom() {
    window.scrollTo(0,  document.body.scrollHeight);
  }
  const obj = {
    1: {
      title: "Navegação",
      frase:
        "Acompanhe seu catálogo online, e saiba o que vender no momento exato.",
    },
    2: {
      title: "Segmentação",
      frase:
        "Descubra as categorias que mais vendem e monte campanhas específicas.",
    },
    3: {
      title: "Ativação",
      frase: "Acompanhe em tempo real o histórico de seus usuários.",
    },
    4: {
      title: "Engajamento",
      frase:
        "Engaje seu cliente no momento e hora certa com uma estratégia mais eficiente",
    },
    5: {
      title: "Reports",
      frase:
        " Crie relatórios para analisar os seus resultados e melhorar seu desempenho.",
    },
    6: {
      title: "Conversões",
      frase:
        "Acompanhe o crescimento do seu ROI através de um dashboard mais eficiente.",
    },
  };

  const [variableFrase, setVariableFrase] = useState(1);
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setTransition(true);
      setVariableFrase((prevFrase) => (prevFrase === 6 ? 1 : prevFrase + 1));

      setTimeout(() => {
        setTransition(false);
      }, 2000);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <article className={styles.mainIntro}>
      <NavbarComponent />
      <div className={styles.containerIntro}>
        <div className={styles.mainTitle}>
          <h1 className={styles.titlePlataforma}>Plataforma 360°</h1>
          <p className={styles.subtitleIntro}>
            para você vender todos os dias!
          </p>
        </div>

        <div className={styles.carouselFrases}>
          <div className={styles.backgroundLine}>.</div>
          <div className={styles.containerCarousel}>
            <img src={iconRight} alt="icon" className={styles.iconLeft}/>
            <h3 className={transition ? styles.transition : ""}>
              {obj[variableFrase].title}
            </h3>
            <p className={transition ? styles.transition : ""}>
              {obj[variableFrase].frase}
            </p>
            <img src={iconLeft} alt="icon" className={styles.iconRight}/>
          </div>
          <div className={styles.backgroundLine}>.</div>
        </div>
        <button className={styles.btnIntro} onClick={toBottom}>Fale Conosco</button>
      </div>
    </article>
  );
};

export default Intro;
