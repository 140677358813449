import Intro from "../Intro/Intro";
import Persona from "../PersonaNeeds/Persona";
import EncanteCliente from "../EncanteSeuCliente/EncanteCliente";
import Comportamento from "../Comportamento/Comportamento";
import Carousel from "../CarouselCliente/Carousel";
import Footer from "../../components/Footer/Footer";

import CardRoi from "../../components/CardRoi/Card";
import Strategy from "../../components/EstrategiaDeNegocio/Strategy";
import Forms from "../../components/Forms/Forms";
import CookieBar from "../../components/CookieBar/CookieBar";
import ScrollTop from "../../components/ScrollTop/ScrollTop"
import WhatsAppBtn from '../../components/whatsAppButton/whatsAppButton'

const DefaultPage = () => {
  return (
    <div>
      <WhatsAppBtn/>
      <ScrollTop/>
      <CookieBar />
      <Intro />
      <Persona />
      <EncanteCliente />
      <Comportamento />
      <Carousel />
      <CardRoi />
      <Strategy />
      <Forms />
      <Footer />
    </div>
  );
};

export default DefaultPage;
