import './DynamicCarousel.css'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { useRef } from "react";
import { Autoplay } from "swiper/modules";

const DynamicCarousel = (props) => {

  return (
    <div className="containerCarouselDynamic">
    <div className="mainCarouselDynamic">
    <Swiper
          slidesPerView={3}
          spaceBetween={0}
          centeredSlides={true}
          pagination={{
            clickable: false,
          }}
          // onSlideChange={(e) => console.log("testando transição")}
          infinite="true"
          loop={true}
          modules={[Autoplay]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          // onSwiper={(swiper) => console.log(swiper)}
          className="mySwipersc"
          breakpoints={{
            1920: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          <SwiperSlide>
            {/* <div className="contentCard"> */}
             <img src={props.img} alt='img'/>
            {/* </div> */}
          </SwiperSlide>
          <SwiperSlide>
            {/* <div className="contentCard"> */}
             <img src={props.img2} alt='img'/>
            {/* </div> */}
          </SwiperSlide>
          <SwiperSlide>
            {/* <div className="contentCard"> */}
             <img src={props.img3} alt='img'/>
            {/* </div> */}
          </SwiperSlide>
          <SwiperSlide>
            {/* <div className="contentCard"> */}
             <img src={props.img4} alt='img'/>
            {/* </div> */}
          </SwiperSlide>
          <SwiperSlide>
            {/* <div className="contentCard"> */}
             <img src={props.img5} alt='img'/>
            {/* </div> */}
          </SwiperSlide>
          <SwiperSlide>
            {/* <div className="contentCard"> */}
             <img src={props.img6} alt='img'/>
            {/* </div> */}
          </SwiperSlide>
        </Swiper>
      <div>
    </div>
      <hr />
    </div>
  </div>
  )
}

export default DynamicCarousel