import styles from "./EncanteCliente.module.css";
import coolShirt from "../../img/Icons-PNG/icon-002.png";
import Line from "../../img/icons-SVG/Line-02.svg";

const EncanteCliente = () => {
  return (
    <div className={styles.mainCliente}>
      <div className={styles.containerCliente}>
        <h2>
          #Encante <span>seu cliente</span>
        </h2>
        <div className={styles.textAndImage}>
          <div className={styles.text}>
            <img src={Line} alt="line" />
            <p>
              Se você tem um e-commerce, precisa conhecer nossa plataforma para
              impulsionar suas vendas oferecendo precisão de dados, engajando
              seu cliente com ativações de ofertas e cupons, aumentando as
              conversões com baixo investimento.
            </p>
            <img src={Line} alt="line" />
          </div>
          <div className={styles.image}>
            <img src={coolShirt} alt="Imagem do encante" />
          </div>
        </div>

        <div className={styles.cardsCliente}>
          <div className={styles.cardIndividual}>
            <h3>Navegação Personalizada</h3>
            <p>
              Cada cliente que entrar na sua loja, terá uma experiência única,
              com uma indicação especial do produto escolhido.
            </p>
          </div>

          <div className={styles.cardIndividual}>
            <h3>Customização e Ativação</h3>
            <p>
              Possui toda a sua identidade visual, com uma estratégia que varia
              de acordo com o que seu usuário interage.
            </p>
          </div>

          <div className={styles.cardIndividual}>
            <h3>
              Aumento
              <br className={styles.brCliente}/> de ROI
            </h3>
            <p>
              Seus clientes terão produtos de maior relevância da categoria
              acessada aumentando a conversão.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EncanteCliente;
