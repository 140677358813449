import "./Carousel.css";
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Line from "../../img/icons-SVG/Line-05.svg";

import iconCheck from "../../img/icons-SVG/icon-005.svg";
import iconRepeat from "../../img/icons-SVG/icon-006.svg";
import iconEtiqueta from "../../img/icons-SVG/icon-007.svg";
import iconPeople from "../../img/icons-SVG/icon-008.svg";
import iconMegaPhone from "../../img/icons-SVG/icon-001.svg";
import iconCard from "../../img/icons-SVG/icon-003.svg";
import iconPincel from "../../img/icons-SVG/icon-004.svg";
import iconHeart from "../../img/icons-SVG/icon-002.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination, Autoplay } from "swiper/modules";

export default function App() {
  return (
    <div className="mainCarousel">
      <h1>Chame a atenção do seu cliente na hora certa!</h1>

      <img src={Line} alt="line" />
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        // onSlideChange={(e) => console.log("testando transição")}
        infinite="true"
        loop={true}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        // onSwiper={(swiper) => console.log(swiper)}
        className="mySwiper"
        breakpoints={{
          1920: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        }}
      >
        <SwiperSlide>
          <div className="contentCard">
            <div className="iconCard">
              <img src={iconMegaPhone} alt="icon" />
            </div>
            <h4 className="titleCardCarousel">Promova</h4>
            <p>
              Crie promoções de um produto específico e aumente sua chance
              conversão.
            </p>
            <img src={Line} alt="line-04" className="lineClass" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="contentCard">
            <div className="iconCard">
              <img src={iconHeart} alt="icon" />
            </div>
            <h4 className="titleCardCarousel">Engaje +</h4>
            <p>
              Chame atenção dos seus clientes ao acessar sua loja de forma
              personalizada.
            </p>
            <img src={Line} alt="line-04" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="contentCard">
            <div className="iconCard">
              <img src={iconCard} alt="icon" />
            </div>
            <h4 className="titleCardCarousel">Surpreenda</h4>
            <p>
              Crie ofertas exclusivas apenas para os seus visitantes anônimos
              que acessam o seu site.
            </p>
            <img src={Line} alt="line-04" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="contentCard">
            <div className="iconCard">
              <img src={iconPincel} alt="icon" />
            </div>
            <h4 className="titleCardCarousel">Personalize</h4>
            <p>
              {" "}
              Fidelize e ative seu cliente com uma oferta exclusiva e única.
            </p>
            <img src={Line} alt="line-04" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="contentCard">
            <div className="iconCard">
              <img src={iconCheck} alt="icon" />
            </div>
            <h4 className="titleCardCarousel">Check out</h4>
            <p>
              Faça conversões de carrinhos abandonados, segmentando, engajando e
              ativando.
            </p>
            <img src={Line} alt="line-04" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="contentCard">
            <div className="iconCard">
              <img src={iconEtiqueta} alt="icon" />
            </div>
            <h4 className="titleCardCarousel">Converta</h4>
            <p>
              Envie ofertas dos seus produtos, de forma simultânea pelo canal
              digital de interação.
            </p>
            <img src={Line} alt="line-04" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="contentCard">
            <div className="iconCard">
              <img src={iconRepeat} alt="icon" />
            </div>
            <h4 className="titleCardCarousel">Condicione</h4>
            <p>
              Mostre a importância do seu produto e não desperdice tempo criando
              cupons genéricos que não vendem!
            </p>
            <img src={Line} alt="line-04" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="contentCard">
            <div className="iconCard">
              <img src={iconPeople} alt="icon" />
            </div>
            <h4 className="titleCardCarousel">Humanize</h4>
            <p>
              Utilize nossa IA para criar comunicação humanizada com seus
              clientes.
            </p>
            <img src={Line} alt="line-04" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
