import React, { useState, useEffect } from "react";

import "./CookieBar.css";
import { Link } from "react-router-dom";

const CookieBar = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (document.cookie.includes("accept_cookiesEcom")) {
      setIsVisible(false);
    }
  }, []);

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const handleAcceptCookies = () => {
    setCookie("accept_cookiesEcom", "accept_cookiesEcom", 30);
    setIsVisible(false);
  };

  if (isVisible === false || document.cookie.includes("accept_cookiesEcom")) {
    return null;
  }

  return (
    <div>
      <div id="privacy-policy" className="privacy-policy">
        <div className="contentPrivacy">
          <div className="textCookie">
            <h5 className="titleCookie">
              Para lhe proporcionar a melhor experiência online, este site
              utiliza cookies.
            </h5>
            <div className="paragrafosAutorizacao">
              <p className="paragraphCookie">
                Usamos cookies para fornecer os recursos e serviços oferecidos
                em nosso site para melhorar a experiência do usuário.
              </p>
              <p className="paragraphCookie">
                Ao continuar navegando neste site, você concorda com o uso
                destes cookies. Leia nossa&nbsp;
                <Link
                  to="/policies"
                  href="/politicasEcomTraffega.pdf"
                  rel="noreferrer"
                  className="linkPolitica"
                >
                  <span className="linkPoliticaPrivacidade">
                    Política de Privacidade&nbsp;
                  </span>
                </Link>
                para saber mais.
              </p>
            </div>
          </div>
          <button id="close-policy" onClick={handleAcceptCookies}>
            Aceitar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBar;
