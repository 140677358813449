import React, { useState } from "react";
import styles from "./Comportamento.module.css";
import artBoardLeft from "../../img/icons-SVG/artboardleft.svg";
import artBoardRight from "../../img/icons-SVG/artboardright.svg";
import pessoa1op from "../../img/Icons-PNG/pessoa-01-op.png";
import pessoa2op from "../../img/Icons-PNG/pessoa-02-op.png";
import pessoa3op from "../../img/Icons-PNG/pessoa-03-op.png";
import pessoa4op from "../../img/Icons-PNG/pessoa-04-op.png";
import pessoa5op from "../../img/Icons-PNG/pessoa-05-op.png";
/* */
import pessoa1 from "../../img/Icons-PNG/pessoa-01-f.png";
import pessoa2 from "../../img/Icons-PNG/pessoa-02-f.png";
import pessoa3 from "../../img/Icons-PNG/pessoa-03-f.png";
import pessoa4 from "../../img/Icons-PNG/pessoa-04-f.png";
import pessoa5 from "../../img/Icons-PNG/pessoa-05-f.png";

const Comportamento = () => {
  const [hoverActive, setHoverActive] = useState(false);
  const [hoverActive2, setHoverActive2] = useState(false);
  const [hoverActive3, setHoverActive3] = useState(false);
  const [hoverActive4, setHoverActive4] = useState(false);
  const [hoverActive5, setHoverActive5] = useState(false);

  const handleHover = (setHover) => {
    setHover(true);
  };

  const handleHoverOut = (setHover) => {
    setHover(false);
  };

  return (
    <div className={styles.mainComportamento}>
      <img src={artBoardLeft} alt="artboard" className={styles.artBoardLeft} />
      <div className={styles.containerComportamento}>
        <h1>
          Comportamento <br /> <span>dos seus usuários</span>
        </h1>
        <div className={styles.personas}>
          <img
            src={hoverActive ? pessoa1 : pessoa1op}
            alt="pessoa"
            onMouseOver={() => handleHover(setHoverActive)}
            onMouseOut={() => handleHoverOut(setHoverActive)}
          />
          <img
            src={hoverActive2 ? pessoa2 : pessoa2op}
            alt="pessoa"
            onMouseOver={() => handleHover(setHoverActive2)}
            onMouseOut={() => handleHoverOut(setHoverActive2)}
          />
          <img
            src={hoverActive3 ? pessoa3 : pessoa3op}
            alt="pessoa"
            onMouseOver={() => handleHover(setHoverActive3)}
            onMouseOut={() => handleHoverOut(setHoverActive3)}
          />
          <img
            src={hoverActive4 ? pessoa4 : pessoa4op}
            alt="pessoa"
            onMouseOver={() => handleHover(setHoverActive4)}
            onMouseOut={() => handleHoverOut(setHoverActive4)}
          />
          <img
            src={hoverActive5 ? pessoa5 : pessoa5op}
            alt="pessoa"
            onMouseOver={() => handleHover(setHoverActive5)}
            onMouseOut={() => handleHoverOut(setHoverActive5)}
          />
        </div>
        <p>
          A análise do dados de navegação é fundamental para compreender o
          comportamento dos usuários. Isso permite identificar tendências e
          padrões de comportamento em segmentos que precisam de melhorias,
          proporcionando uma experiência do usuário mais eficiente.
        </p>
      </div>
      <img
        src={artBoardRight}
        alt="artboard"
        className={styles.artBoardRight}
      />
    </div>
  );
};

export default Comportamento;
