import './App.css';
import DefaultPage from './pages/DefaultPage/DefaultPage';

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Vitrine from './pages/VitrineShowCase/Vitrine'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<DefaultPage />} />
          <Route path='/policies' element={<PrivacyPolicy/>} />
          <Route path='/showcase' element={<Vitrine/>} />
          <Route path='*' element={<DefaultPage/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
