import NavbarComponent from "../../components/NavBarShowCase/NavBar";
import styles from "./Vitrine.module.css";
import ShowCaseImg from "../../img/showcasetutorial.png";
import Footer from "../../components/Footer/Footer";
import RefEsquerda from "../../img/refEsquerda.png";
import { useEffect, useState } from "react";
import RefDireita from "../../img/refDireita.png";
import RefCentral from "../../img/refCentral.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Link } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward, IoIosSend } from "react-icons/io";
import { MdLock } from "react-icons/md";
import Carousel from "../../components/DynamicCarouselShowCase/DynamicCarousel";
import axios from "axios";
import ScsForScPage from "../../img/scforscpage.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//personalidade
import personalidade1 from "../../img/Personalidade-01.png";
import personalidade2 from "../../img/Personalidade-02.png";
import personalidade3 from "../../img/Personalidade-03.png";
import personalidade4 from "../../img/Personalidade-04.png";
import personalidade5 from "../../img/Personalidade-05.png";
import personalidade6 from "../../img/Personalidade-06.png";

//borda
import borda1 from "../../img/Borda-01.png";
import borda2 from "../../img/Borda-02.png";
import borda3 from "../../img/Borda-03.png";
import borda4 from "../../img/Borda-04.png";
import borda5 from "../../img/Borda-05.png";
import borda6 from "../../img/Borda-06.png";

//degrade
import degrade1 from "../../img/degrade-01.png";
import degrade2 from "../../img/degrade-02.png";
import degrade3 from "../../img/degrade-03.png";
import degrade4 from "../../img/degrade-04.png";
import degrade5 from "../../img/degrade-05.png";
import degrade6 from "../../img/degrade-06.png";

//generico
import generico1 from "../../img/Generico-01.png";
import generico2 from "../../img/Generico-02.png";
import generico3 from "../../img/Generico-03.png";
import generico4 from "../../img/Generico-04.png";
import generico5 from "../../img/Generico-05.png";
import generico6 from "../../img/Generico-06.png";

//institucional
import institucional1 from "../../img/institucional-01.png";
import institucional2 from "../../img/institucional-02.png";
import institucional3 from "../../img/institucional-03.png";
import institucional4 from "../../img/institucional-04.png";
import institucional5 from "../../img/institucional-05.png";
import institucional6 from "../../img/institucional-06.png";

const Vitrine = () => {
  const [page, setPage] = useState(true);
  const [loader, setLoader] = useState(false);

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [site, setSite] = useState("");
  const origin = "ecomtraffega.com.br/showcase";

  const submitForm = (formEvent) => {
    formEvent.preventDefault();
    setLoader(true);

    const submitParams = {
      nome,
      email,
      phone,
      email,
      empresa,
      site,
      origin,
    };
    const submitUrl = "https://form.ecomtraffega.com.br/op/forms/send";

    axios
      .post(submitUrl, submitParams)
      .then(function (response) {
        setCookie("showcase_cookie", "showcase_cookie", 1);
        toast.success("Formulário enviado com sucesso!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setPage(true);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log("Erro ao salvar dados: ", error);
        toast.error("Tente novamente mais tarde.", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    return false;
  };

  function setCookie(cname, cvalue, exdays) {
    if (email === "demo@ecomtraffega.com.br") {
      exdays = 365;
    }
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cookieName) {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.indexOf(cookieName + "=") === 0) {
        return cookie.substring(cookieName.length + 1, cookie.length);
      }
    }
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setCookie("showcase_cookie", "showcase_cookie", 1);
  //   setPage(true);
  // };

  let cookie = getCookie("showcase_cookie");
  useEffect(() => {
    if (cookie) {
      setPage(true);
    } else {
      setPage(false);
    }
  }, [cookie]);

  return (
    <>
      {!page ? (
        <>
          <div className={styles.mainContainerScSection}>
            {/* <NavbarComponent/> */}
            {!loader ? (
              ""
            ) : (
              <div className={styles.containerLoader}>
                <div className={styles.customLoader}></div>
              </div>
            )}
            <div className={styles.blur}>
              <div className={styles.mainContent}>
                <div className={styles.divLock}>
                  <img src={ScsForScPage} alt="" />
                </div>
                <form className={styles.formsShowCase} onSubmit={submitForm}>
                  <p>
                    Explore o ShowCase: Preencha o formulário para começar sua
                    jornada de descoberta
                  </p>
                  <input
                    type="text"
                    name="nome"
                    id="oame"
                    placeholder="Nome"
                    required
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder="Telefone"
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <input
                    type="text"
                    name="empresa"
                    id="empresa"
                    placeholder="Empresa"
                    value={empresa}
                    onChange={(e) => setEmpresa(e.target.value)}
                  />
                  <input
                    type="text"
                    name="site"
                    id="site"
                    placeholder="Site"
                    value={site}
                    onChange={(e) => setSite(e.target.value)}
                  />
                  <button type="submit">
                    Enviar
                    <span>
                      <IoIosSend />
                    </span>
                  </button>
                  <Link to="/home" className={styles.linkToHome}>
                    Voltar
                  </Link>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <article className={styles.containerVitrine}>
            <NavbarComponent />
            <section className={styles.introVitrine}>
              <div className={styles.title}>
                <h1>Seja bem vindo ao Showcase</h1>
                <p>
                  Entenda como funciona, quais as principais referências de
                  acordo com seu produto e tire suas dúvidas iniciais sobre a
                  ferramenta.
                </p>
              </div>

              <div className={styles.configShowCase}>
                <div className={styles.showCaseImg}>
                  <img src={ShowCaseImg} alt="showCaseImg" />
                </div>

                <div className={styles.showCaseList}>
                  <li>
                    {" "}
                    1. Botão de minimizar. Uma vez minimizado, um ícone surgirá
                    para abri-lo novamente.
                  </li>
                  <li>
                    {" "}
                    2. Título ou imagem de destaque do seu produto ou serviço
                    disponibilizado.
                  </li>
                  <li>
                    {" "}
                    3. Texto para detalhar especificações dos seus produtos ou
                    serviços.
                  </li>
                  <li>
                    {" "}
                    4. Imagem ou vídeo curto do seu produto ou serviço em
                    destaque.
                  </li>
                  <li>
                    {" "}
                    5. Formulário básico com e-mail e telefone para
                    identificação de clientes.
                  </li>
                  <li>
                    {" "}
                    6. Botões informativos que serão liberados após
                    preenchimento do formulário.
                  </li>
                </div>
              </div>

              <div className={styles.lastParagraph}>
                <p>
                  Veja a seguir as diferentes possibilidades do nosso Showcase e
                  encontre o que mais se adequa ao seu negócio.
                </p>
              </div>
            </section>
            <ToastContainer />
            {/* Sessão do carousel */}
            <section className={styles.sectionLayout}>
              <div className={styles.carousel}>
                <div className={styles.tabs}>
                  <Tabs className={styles.tabsContainer}>
                    <TabList className={styles.tabList}>
                      {/* <span><IoIosArrowBack /></span> */}
                      <Tab>Degradê</Tab>
                      <span>
                        <hr />
                      </span>
                      <Tab>Institucional</Tab>
                      <span>
                        <hr />
                      </span>
                      <Tab>Genérico</Tab>
                      <span>
                        <hr />
                      </span>
                      <Tab>Bordas</Tab>
                      <span>
                        <hr />
                      </span>
                      <Tab>Personalizado</Tab>
                      {/* <span><IoIosArrowForward/></span> */}
                    </TabList>
                    <TabPanel>
                      <Carousel
                        img={degrade1}
                        img2={degrade2}
                        img3={degrade3}
                        img4={degrade4}
                        img5={degrade5}
                        img6={degrade6}
                      />
                    </TabPanel>
                    <TabPanel>
                      <Carousel
                        img={institucional1}
                        img2={institucional2}
                        img3={institucional3}
                        img4={institucional4}
                        img5={institucional5}
                        img6={institucional6}
                      />
                    </TabPanel>
                    <TabPanel>
                      <Carousel
                        img={generico1}
                        img2={generico2}
                        img3={generico3}
                        img4={generico4}
                        img5={generico5}
                        img6={generico6}
                      />
                    </TabPanel>
                    <TabPanel>
                      <Carousel
                        img={borda1}
                        img2={borda2}
                        img3={borda3}
                        img4={borda4}
                        img5={borda5}
                        img6={borda6}
                      />
                    </TabPanel>
                    <TabPanel>
                      <Carousel
                        img={personalidade1}
                        img2={personalidade2}
                        img3={personalidade3}
                        img4={personalidade4}
                        img5={personalidade5}
                        img6={personalidade6}
                      />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>

              <div className={styles.layout}>
                <div className={styles.layoutTitle}>
                  <h2>Layout e disposição do ShowCase</h2>
                  <p>
                    O Showcase no computador pode ser localizado a esquerda ou a
                    direita da tela, de acordo com a sua preferência, e na
                    versão de smartphone ele aparecerá ao centro da tela.
                    Confira os exemplos abaixo:
                  </p>
                </div>
                <div className={styles.refLayout}>
                  <img src={RefEsquerda} alt="ref" />
                  <img src={RefCentral} alt="ref" />
                  <img src={RefDireita} alt="ref" />
                </div>

                <div className={styles.lastStylesParagraph}>
                  <p>
                    Agora que você tem uma prévia de como nosso Showcase
                    funciona, que tal entrar em contato e começar a vender?{" "}
                  </p>
                </div>
              </div>
            </section>
            <Footer />
          </article>
        </>
      )}
    </>
  );
};

export default Vitrine;
