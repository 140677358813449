import WhiteLogo from "../../img/Icons-PNG/logo-branco.png";
import ColorLogo from "../../img/Icons-PNG/logo-color.png";
import { BiLogoWhatsapp } from "react-icons/bi";
import "./NavbarComponent.css";
import { Link } from "react-router-dom";
import { useState } from "react";

function NavbarComponent() {
  const [navbar, setNavbar] = useState(false);

  return (
    <>
      {
        <div className={!navbar ? "navbarStructure" : "navbarStructureConfig"}>
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                <img
                  src={!navbar ? WhiteLogo : ColorLogo}
                  alt="icon"
                  className="logoNav"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <p
                  className={!navbar ? "verticalLine" : "verticalLineConfig"}
                ></p>
                <ul className="navbar-nav">
                  <li className={!navbar ? "nav-item" : "nav-item-config"}>
                    <Link
                      to="/"
                      className="nav-link"
                      aria-current="page"
                      href="#"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/showcase">
                      ShowCase
                    </Link>
                  </li>
                  <li className={!navbar ? "nav-item" : "nav-item-config"}>
                    <a
                      className="nav-link"
                      href="https://amwgroup.com.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Group
                    </a>
                  </li>
                  <li className={!navbar ? "nav-item" : "nav-item-config"}>
                    <a
                      className="nav-link"
                      href="https://traffega.com.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Traffega
                    </a>
                  </li>
                  <li className={!navbar ? "nav-item" : "nav-item-config"}>
                    <a
                      className="nav-link"
                      href="https://amwgroup.com.br/about"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sobre
                    </a>
                  </li>
                  <li
                    className={
                      !navbar ? "nav-item lastCont" : "nav-item-config lastCont"
                    }
                  >
                    <a
                      className="nav-link"
                      aria-disabled="true"
                      id="contate"
                      href="https://api.whatsapp.com/send?phone=5511992089747&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20E-COM%20Traffega."
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contate-nos
                      <BiLogoWhatsapp className="logoWhatsapp" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      }
    </>
  );
}

export default NavbarComponent;
